import React from "react";
import { useState, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";

import { Col, Button, Form, Label } from "reactstrap";

import Dropzone from "react-dropzone-uploader";
import axios from "axios";
import { toast } from "react-toastify";
import { URL, SIMPLE_URL } from "../../../../../env";

import { translate } from "react-switch-lang";
import SweetAlert from "sweetalert2";

const GalleryImages = (props) => {
  const trans = props.t;
  const [ImageGallery, setImageGallery] = useState([]);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm({ shouldFocusError: true });

  const handleGalleryImages = ({ meta, file }, status, allFiles) => {
    if (status == "error_file_size") {
      let files_size_error = allFiles.find(
        (item) => item.file.name == file.name
      );
      files_size_error.remove();
      let files_array = allFiles.filter((item) => item.file.name != file.name);
      setValue("imagegallery", files_array);
      setError(
        "imagegallery",
        {
          type: "string",
          message: trans("Maximum file size is 2 MB for each file."),
        },
        {
          shouldFocus: true,
        }
      );
    }
    if (status == "done") {
      setValue("imagegallery", allFiles);
      setError("imagegallery", {
        shouldFocus: false,
      });
    }
  };

  const onSubmit = (data) => {
    console.log("this is submitted data", data);
    const form_data = new FormData();
    if (data.imagegallery != undefined && data.imagegallery.length > 0) {
      data.imagegallery.map((item) => {
        console.log(" data.imagegallery - item.file", item.file);
        form_data.append("imagegallery[]", item.file);
      });
    }

    axios({
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token123"),
      },
      url: `${URL}/csv_gallery_images`,
      data: form_data,
    })
      .then((response) => {
        console.log("response ssss", response);
        if (response.data.success === true) {
          toast.success(trans("successfull"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          // -------
          getData();
        } else {
          toast.error(trans("failed"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.log("create-product-response-error", error.response);
        const error_message = error.response.data.message;
        console.log(
          "create-product-response-error_error_message",
          error_message
        );
      });
  };

  const getData = async () => {
    const response = await axios.get(`${URL}/csv_gallery_images`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token123"),
      },
    });
    console.log("resp11", response);
    const gal_images = response.data.csv_gallery_images;
    setImageGallery(gal_images);
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteGalleryImage = (id, product_id) => {
    SweetAlert.fire({
      title: trans("Are you sure?"),
      text: trans("Once deleted, you will not be able to recover it!"),
      icon: "error",
      showCancelButton: true,
      cancelButtonText: trans("Cancel"),
      confirmButtonText: trans("Delete"),
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        console.log("result-value", result);
        console.log("image id", id);
        console.log("product id", product_id);
        await axios
          .delete(`${URL}/csv_gallery_images/${id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token123"),
            },
          })
          .then(() => {
            getData();

            SweetAlert.fire({
              icon: "success",
              title: trans("Deleted"),
              text: trans("Your item has been deleted."),
              confirmButtonText: trans("OK"),
            });
          });
      }
    });
  };

  return (
    <Fragment>
      <Form
        className="needs-validation"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-row">
          <Col md="12 mb-3">
            <Label htmlFor="validationCustom02">{trans("Image Gallery")}</Label>
            <Dropzone
              maxFiles={8}
              multiple={true}
              canCancel={false}
              minSizeBytes={0}
              maxSizeBytes={2000000}
              accept="image/*"
              inputContent={trans("Drop Gallery Images")}
              styles={{
                dropzone: { height: 300 },
                dropzoneActive: { borderColor: "green" },
              }}
              getUploadParams={({ meta }) => {
                return { url: "https://httpbin.org/post" };
              }}
              onChangeStatus={handleGalleryImages}
              {...register("imagegallery")}
            />
            <span>{errors.imagegallery && errors.imagegallery.message}</span>
          </Col>
        </div>
        <Button color="success">{trans("Save")}</Button>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            paddingTop: 10,
          }}
        >
          {ImageGallery &&
            ImageGallery.length > 0 &&
            ImageGallery.map((gallery, i) => {
              return (
                <div>
                  <div
                    className="mx-1"
                    style={{
                      position: "relative",
                      padding: 10,
                      border: "1px solid lightgray",

                      height: "12pc",
                    }}
                  >
                    <img
                      src={
                        SIMPLE_URL + `/images/CSVGalleryImages/${gallery.image}`
                      }
                      width={100}
                      alt={gallery.image}
                      style={{
                        marginTop: 20,
                        height: "-webkit-fill-available",
                      }}
                    />
                    <span
                      className="fa fa-trash"
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        background: "white",
                        color: "red",
                        cursor: "pointer",
                        fontSize: 16,
                      }}
                      onClick={() =>
                        deleteGalleryImage(gallery.id, gallery.product_id)
                      }
                    ></span>
                    <span
                      className="fa fa-file-text-o"
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 30,
                        background: "white",
                        color: "green",
                        cursor: "pointer",
                        fontSize: 16,
                      }}
                      onClick={(event) => {
                        var text = gallery.image;
                        navigator.clipboard.writeText(text).then((_) => {
                          console.log("copied successfully!");
                          toast.success(trans("Image name copied"), 5000);
                        });
                      }}
                    ></span>
                  </div>
                </div>
              );
            })}
        </div>
      </Form>
    </Fragment>
  );
};

export default translate(GalleryImages);
