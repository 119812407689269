/** SaaS URL */
// export const URL = "https://saas-ecommerce.royaldonuts.xyz/api";

// export const SIMPLE_URL = "https://saas-ecommerce.royaldonuts.xyz";

/** RD - Url */
export const SIMPLE_URL = "https://backend.royal-donuts.gr";
export const URL = "https://backend.royal-donuts.gr/api";

// export const SIMPLE_URL = "https://backend.royal-donuts.fr";
// export const URL = "https://backend.royal-donuts.fr/api";

// export const SIMPLE_URL =
//   "http://localhost/Merge_RD_API/laravel_merge_rd/public";
// export const URL = "http://localhost/Merge_RD_API/laravel_merge_rd/public/api";
