/* eslint-disable no-unused-vars */
import React, { useEffect, Fragment } from "react";
import axios from "axios";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Badge,
} from "reactstrap";
import { useState } from "react";
import DataGrid from "../../dataTable/dataTable";
import moment from "moment";
import { toast } from "react-toastify";

import { URL } from "../../../env";
import { translate } from "react-switch-lang";

const SupplierBacklogs = (props) => {
  const trans = props.t;
  const [backlogs, setBacklogs] = useState([]);

  const columns = [
    { field: "index", headerName: "#", width: 80 },
    { field: "subject_id", headerName: trans("Id"), width: 180 },
    { field: "causer_type", headerName: trans("User"), width: 180 },
    { field: "subject_type", headerName: trans("Object"), width: 180 },
    {
      field: "action",
      headerName: trans("Action"),
      cellClassName: "MuiDataGrid-cell-action-customstyles",
      width: 180,
    },
    { field: "created_at", headerName: trans("Time"), width: 180 },
    {
      field: "comments",
      headerName: trans("Comments"),
      editable: true,
      width: 240,
    },
  ];

  useEffect(() => {
    const getBacklogs = async () => {
      const response = await axios.get(`${URL}/backlogs`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token123"),
        },
      });
      console.log("backlogs", response);
      const logs = response.data.logs;
      logs.map((item, index) => (item["index"] = index + 1));
      setBacklogs(logs);
    };

    getBacklogs();
  }, []);

  const EditCommentBacklog = (params, event) => {
    console.log("FUNCTION RACHEDDDDDDDDD");
    console.log(
      "params ---------------------",
      params,
      " ---------- edit ----------",
      event
    );
    if (params) {
      axios({
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token123"),
        },
        url: `${URL}/save_comment/${params.id}/${event.target.value}`,
      })
        .then((response) => {
          if (response.data.success === true) {
            toast.success(trans("successfull"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.log("datable-error:", error);
        });
    }
  };

  return (
    <>
      <Fragment>
        <Breadcrumb
          breadcrumbtitle={trans("Purchase Module") + " " + trans("List")}
          parent={trans("Purchase Module")}
          title={trans("Backlogs")}
          subtitle={trans("List")}
        />
        <Container fluid={true}>
          <Card>
            {/* <CardHeader>
              <h5>{trans("Backlogs")}</h5>
            </CardHeader> */}
            <CardBody>
              <DataGrid
                columns={columns}
                rows={backlogs}
                dataLength={backlogs}
                onCellEditStop={EditCommentBacklog}
              />
            </CardBody>
          </Card>
        </Container>
      </Fragment>
    </>
  );
};

export default translate(SupplierBacklogs);
