/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/rules-of-hooks */
import { Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./instagram.css";

const instagram = (props) => {
  const { instagramToken } = props;
  const [instaValidToken, setInstaValidToken] = useState(true);

  // console.log("instagramToken", instagramToken)

  const defaultInstaFeedToken =
    "IGQVJVSEwwbFNRX2c2eVdqZAUFfZAHdDOVVWdC1jMkFNLWw0VXRucjVINElwcmpYeDlNeGhtUVN1MDFvQjkyUjlZAeWVxSHRUc19mTE5QU2J1akI0VkM5UTNHSEo0V2R1Rk1NRzlLbDFzZAktOMU14TTdHTAZDZD";

  const userInstaFeed = new Instafeed({
    get: "user",
    target: "insta_all_images",
    resolution: "standard_resolution",
    limit: 4,
    filter: function (image) {
      // ensure the filter doesn't reject any images
      // return image.type === 'image'; //display only images
      setInstaValidToken(true);
      return true;
    },
    success: function (success) {
      console.log("Success --------------", success);
      setInstaValidToken(true);
    },
    error: function (error) {
      console.log("ERROR ---------------", error);
      setInstaValidToken(false);
    },
    accessToken: instagramToken ? instagramToken : defaultInstaFeedToken,
  });

  useEffect(() => {
    if (Instafeed != undefined || instagramToken != null) {
      console.log(
        " INSTA VLIAD -----",
        instaValidToken,
        " ---------- props.instagramToken 222222222222222222222 -------------",
        props.instagramToken
      );
      userInstaFeed.run(); // Display Media from Instagram.
    }
  }, [userInstaFeed]);

  console.log(
    " INSTA VLIAD -----",
    instaValidToken,
    " ---------- props.instagramToken -------------",
    instagramToken
  );

  return (
    <>
      {instagramToken != "" && (
        <div
          container
          id="insta_all_images"
          style={
            instaValidToken == true ? { display: "block" } : { display: "none" }
          }
        >
          {/* All Instagram Images Display Here */}
        </div>
      )}
      {instaValidToken == false && (
        <div>
          <div
            style={{
              backgroundColor: "#00000010",
              padding: 10,
              marginTop: 5,
            }}
          >
            <span style={{ color: "red", fontSize: 14 }}>
              InstaFeed Token is Invalid !!
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default instagram;
