/* eslint-disable no-lone-blocks */
import React, { Fragment, useEffect, useState } from "react";
import { URL } from "../../../../env";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { translate } from "react-switch-lang";
import CKEditors from "react-ckeditor-component";
import SupplierUnits from "../../../apiData/supplierApiData/supplierUnits";
import SupplierAdmins from "../../../apiData/supplierApiData/supplierAdmins";
import ManageRolesTable from "../../../apiData/manageRolesTable";
import Breadcrumb from "../../../../layout/breadcrumb/index";

const RoleManagement = (props) => {
  const trans = props.t;
  const [BasicLineTab, setBasicLineTab] = useState("1");
  const role = atob(localStorage.getItem("role"));
  const permission = atob(localStorage.getItem("permissions"));
  const setLineTab = (value) => {
    setBasicLineTab(value);
    localStorage.setItem("roleTab", value);
  };
  useEffect(() => {
    if (localStorage.getItem("roleTab") != null) {
      setBasicLineTab(localStorage.getItem("roleTab"));
    }
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumb
          parent={trans("Home Settings")}
          title={trans("Role Management")}
        />

        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Nav className="border-tab" tabs>
                    {(role == "SuperAdmin" || permission.match("Admins")) && (
                      <NavItem style={{ cursor: "pointer" }}>
                        <NavLink
                          className={BasicLineTab === "1" ? "active" : ""}
                          onClick={() => setLineTab("1")}
                        >
                          <i className="fa fa-user"></i>
                          {trans("Admins")}
                        </NavLink>
                      </NavItem>
                    )}
                    {(role == "SuperAdmin" ||
                      permission.match("Manage Roles")) && (
                      <NavItem style={{ cursor: "pointer" }}>
                        <NavLink
                          className={BasicLineTab === "2" ? "active" : ""}
                          onClick={() => setLineTab("2")}
                        >
                          <i className="fa fa-briefcase"></i>
                          {trans("Roles")}
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                  <TabContent activeTab={BasicLineTab}>
                    <TabPane className="fade show" tabId="1">
                      <SupplierAdmins />
                    </TabPane>
                    <TabPane className="fade show" tabId="2">
                      <ManageRolesTable />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default translate(RoleManagement);
