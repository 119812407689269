/* eslint-disable no-lone-blocks */
import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb/index";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  Label,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";
import { translate } from "react-switch-lang";
import CKEditors from "react-ckeditor-component";
import { URL } from "../../../env";

const HomeSettings = (props) => {
  const trans = props.t;

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm({ shouldFocusError: true });

  const navigate = useNavigate();
  const [setting, setSetting] = useState(null);

  const [footerContent, setFooterContent] = useState("");
  const [cookieContent, setCookieContent] = useState("");

  const changeFooter = (evt) => {
    const newContent = evt.editor.getData();
    if (newContent != "" || newContent != null) {
      setFooterContent(newContent);
      setValue("footer", newContent);
    } else {
      setValue("footer", "");
    }
  };

  const changeCookie = (evt) => {
    const newContentCookie = evt.editor.getData();
    if (newContentCookie != "" || newContentCookie != null) {
      setCookieContent(newContentCookie);
      setValue("cookie", newContentCookie);
    } else {
      setValue("cookie", "");
    }
  };

  useEffect(() => {
    axios
      .post(`${URL}/general_home_setting`)
      .then((response) => {
        console.log("responssss", response);
        setSetting(response.data.setting);
        setFooterContent(response.data.setting.footer);
        setCookieContent(
          response.data.setting?.cookie != null && response.data.setting.cookie
        );
      })
      .catch((error) => {
        console.log("erorror", error);
      });
  }, []);

  const onSubmit = (data) => {
    console.log("data", data);
    // throw new Error("STUCK!");
    let formData = new FormData();
    formData.append("background_color", data.background_color);
    formData.append("button_color", data.button_color);
    formData.append("image", data.image);
    formData.append("footer", data.footer);
    formData.append("cookie", data.cookie);
    formData.append("fb_link", data.fb_link);
    formData.append("insta_link", data.insta_link);
    formData.append("t_link", data.t_link);
    formData.append("linkedin_link", data.linkedin_link);
    axios({
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token123"),
      },
      url: `${URL}/general_home_setting`,
      data: formData,
    })
      .then((response) => {
        console.log("RESPOJNSE ----", response);
        if (response.data.success === true) {
          toast.success(trans("successfull"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(trans("Error"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      {/* <Breadcrumb
          parent={trans("Home Settings")}
          title={trans("Home Settings")}
        /> */}

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {/* <CardHeader className="p-0 pb-4 mb-4">
              <h5>{trans("General Settings")}</h5>
            </CardHeader> */}
            <Form
              className="needs-validation"
              noValidate=""
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-row">
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom02">
                    {trans("Background Color")}
                  </Label>
                  <Input
                    className="form-control"
                    name="background_color"
                    type="color"
                    key={setting != null && setting.background_color}
                    defaultValue={
                      setting != null ? setting.background_color : ""
                    }
                    innerRef={register({})}
                  />
                  <span>
                    {errors.background_color?.type == "required" &&
                      trans("field is required")}
                  </span>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom02">
                    {trans("Button Color")}
                  </Label>
                  <Input
                    className="form-control"
                    name="button_color"
                    type="color"
                    key={setting != null && setting.button_color}
                    defaultValue={setting != null ? setting.button_color : ""}
                    innerRef={register({})}
                  />
                  <span>
                    {errors.button_color?.type == "required" &&
                      trans("field is required")}
                  </span>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom02">
                    {trans("Facebook Link")}
                  </Label>
                  <Input
                    className="form-control"
                    name="fb_link"
                    type="text"
                    key={setting != null && setting.fb_link}
                    defaultValue={setting != null ? setting.fb_link : ""}
                    innerRef={register({})}
                  />
                  <span>
                    {errors.fb_link?.type == "required" &&
                      trans("field is required")}
                  </span>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom02">
                    {trans("Instagram Link")}
                  </Label>
                  <Input
                    className="form-control"
                    name="insta_link"
                    type="text"
                    key={setting != null && setting.insta_link}
                    defaultValue={setting != null ? setting.insta_link : ""}
                    innerRef={register({})}
                  />
                  <span>
                    {errors.insta_link?.type == "required" &&
                      trans("field is required")}
                  </span>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom02">
                    {trans("Twitter Link")}
                  </Label>
                  <Input
                    className="form-control"
                    name="t_link"
                    type="text"
                    key={setting != null && setting.twitter_link}
                    defaultValue={setting != null ? setting.twitter_link : ""}
                    innerRef={register({})}
                  />
                  <span>
                    {errors.t_link?.type == "required" &&
                      trans("field is required")}
                  </span>
                </Col>
                <Col md="6 mb-3">
                  <Label htmlFor="validationCustom02">
                    {trans("LinkedIn Link")}
                  </Label>
                  <Input
                    className="form-control"
                    name="linkedin_link"
                    type="text"
                    key={setting != null && setting.linkedin_link}
                    defaultValue={setting != null ? setting.linkedin_link : ""}
                    innerRef={register({})}
                  />
                  <span>
                    {errors.linkedin_link?.type == "required" &&
                      trans("field is required")}
                  </span>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom02">
                    {trans("Footer Text")}
                  </Label>
                  <Controller
                    control={control}
                    name="footer"
                    rules={{ required: false }}
                    // defaultValue={" "}
                    key={setting?.footer != null ? setting.footer : ""}
                    defaultValue={setting?.footer != null ? setting.footer : ""}
                    render={() => (
                      <CKEditors
                        activeclassName="p10"
                        content={footerContent}
                        events={{
                          change: changeFooter,
                        }}
                      />
                    )}
                  />
                  <span>{errors.footer && trans("field is required")}</span>
                </Col>

                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom02">
                    {trans("Cookie Text")}
                  </Label>
                  <Controller
                    control={control}
                    name="cookie"
                    rules={{ required: false }}
                    key={setting?.cookie != null ? setting.cookie : ""}
                    defaultValue={setting?.cookie != null ? setting.cookie : ""}
                    render={() => (
                      <CKEditors
                        activeclassName="p10"
                        content={cookieContent}
                        events={{
                          change: changeCookie,
                        }}
                      />
                    )}
                  />
                  <span>{errors.cookie && trans("field is required")}</span>
                </Col>
              </div>
              <Button type="submit" color="success">
                {trans("Save")}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default translate(HomeSettings);
