/* eslint-disable no-lone-blocks */
import React, { Fragment, useEffect, useState } from "react";
import { URL } from "../../../../env";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumb from "../../../../layout/breadcrumb/index";
import { translate } from "react-switch-lang";
import CKEditors from "react-ckeditor-component";
import SupplierUnits from "../../../apiData/supplierApiData/supplierUnits";
import "../TabsCSS.css";
import DeliveryCompanies from "../../../apiData/supplierApiData/deliveryCompanies";
import SupplierList from "../../../apiData/supplierApiData/supplierList";
import SupplierRules from "../../../apiData/supplierApiData/supplierRules";
import SupplierAssociateRules from "../../../apiData/supplierApiData/supplierAssociateRules";
import CustomCalendar from "../../../custom-calendar/customCalendar";
import { useLocation } from "react-router-dom";

const OrderManagement = (props) => {
  const trans = props.t;
  const [BasicLineTab, setBasicLineTab] = useState("3");

  const location = useLocation();

  const tabValue = location.state?.tabValue;

  // console.log("tabFromtabFrom", tabValue)

  const setLineTab = (value) => {
    setBasicLineTab(value);
    localStorage.setItem("orderTab", value);
  };
  const role = atob(localStorage.getItem("role"));
  const permissions = atob(localStorage.getItem("permissions"));
  useEffect(() => {
    if (tabValue) {
      localStorage.setItem("orderTab", tabValue);
      setBasicLineTab(tabValue);
    } else {
      if (localStorage.getItem("orderTab") != null) {
        setBasicLineTab(localStorage.getItem("orderTab"));
      }
    }
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumb
          parent={trans("Home Settings")}
          title={trans("Order Management")}
        />

        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Nav className="border-tab" tabs>
                    {(role == "SuperAdmin" ||
                      permissions.match("Units") != null) && (
                      <NavItem
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <NavLink
                          className={BasicLineTab === "1" ? "active" : ""}
                          onClick={() => setLineTab("1")}
                        >
                          <i className="fa fa-usd"></i>
                          {trans("Units")}
                        </NavLink>
                      </NavItem>
                    )}
                    {(role == "SuperAdmin" ||
                      permissions.match("Delivery Companies") != null) && (
                      <NavItem
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <NavLink
                          className={BasicLineTab === "2" ? "active" : ""}
                          onClick={() => setLineTab("2")}
                        >
                          <i className="fa fa-motorcycle"></i>
                          {trans("Shipping Companies")}
                        </NavLink>
                      </NavItem>
                    )}
                    {(role == "SuperAdmin" ||
                      permissions.match("Suppliers") != null) && (
                      <NavItem
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <NavLink
                          className={BasicLineTab === "3" ? "active" : ""}
                          onClick={() => setLineTab("3")}
                        >
                          <i className="fa fa-user"></i>
                          {trans("Suppliers")}
                        </NavLink>
                      </NavItem>
                    )}
                    {(role == "SuperAdmin" ||
                      permissions.match("Rules") != null) && (
                      <NavItem
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <NavLink
                          className={BasicLineTab === "4" ? "active" : ""}
                          onClick={() => setLineTab("4")}
                        >
                          <i className="fa fa-check-square-o"></i>
                          {trans("Rules")}
                        </NavLink>
                      </NavItem>
                    )}
                    {(role == "SuperAdmin" ||
                      permissions.match("Associate Rule") != null) && (
                      <NavItem
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <NavLink
                          className={BasicLineTab === "5" ? "active" : ""}
                          onClick={() => setLineTab("5")}
                        >
                          <i className="fa fa-check-square"></i>
                          {trans("Associate Rules")}
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                  <TabContent activeTab={BasicLineTab}>
                    <TabPane className="fade show" tabId="1">
                      <SupplierUnits />
                    </TabPane>
                    <TabPane className="fade show" tabId="2">
                      <DeliveryCompanies />
                    </TabPane>
                    <TabPane className="fade show" tabId="3">
                      <SupplierList />
                    </TabPane>
                    <TabPane className="fade show" tabId="4">
                      <SupplierRules />
                    </TabPane>
                    <TabPane className="fade show" tabId="5">
                      <SupplierAssociateRules />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default translate(OrderManagement);
