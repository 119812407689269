/* eslint-disable no-lone-blocks */
import React, { Fragment, useEffect, useState } from "react";
import { URL } from "../../../../env";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { translate } from "react-switch-lang";
import CKEditors from "react-ckeditor-component";
import SupplierUnits from "../../../apiData/supplierApiData/supplierUnits";
import ListForms from "../../../crm/forms/ListForms";
import FormsListing from "../../roleManagement/FormsListing";
import LeadsMap from "../../../crm/leads-map/leadsMap";
import Breadcrumb from "../../../../layout/breadcrumb/index";
import "../TabsCSS.css";

const LeadManagement = (props) => {
  const trans = props.t;
  const [BasicLineTab, setBasicLineTab] = useState("1");
  const role = atob(localStorage.getItem("role"));
  const permission = atob(localStorage.getItem("permissions"));
  const setLineTab = (value) => {
    setBasicLineTab(value);
    localStorage.setItem("leadTab", value);
  };
  useEffect(() => {
    if (localStorage.getItem("leadTab") != null) {
      setBasicLineTab(localStorage.getItem("leadTab"));
    }
  }, []);

  return (
    <>
      <Fragment>
        <Breadcrumb
          parent={trans("Home Settings")}
          title={trans("Lead Management")}
        />

        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Nav className="border-tab" tabs>
                    {(role == "SuperAdmin" ||
                      permission.match("CRM") ||
                      permission.match("Stage Forms") != null) && (
                      <NavItem
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <NavLink
                          className={BasicLineTab === "1" ? "active" : ""}
                          onClick={() => setLineTab("1")}
                        >
                          <i className="fa fa-file-text"></i>
                          {trans("Stage Forms")}
                        </NavLink>
                      </NavItem>
                    )}
                    {(role == "SuperAdmin" ||
                      permission.match("CRM") ||
                      permission.match("Leads Forms") != null) && (
                      <NavItem
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <NavLink
                          style={{ width: "100%" }}
                          className={BasicLineTab === "2" ? "active" : ""}
                          onClick={() => setLineTab("2")}
                        >
                          <i className="fa fa-id-card-o"></i>
                          {trans("Lead Forms")}
                        </NavLink>
                      </NavItem>
                    )}
                    {(role == "SuperAdmin" ||
                      permission.match("CRM") ||
                      permission.match("Leads Map") != null) && (
                      <NavItem
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <NavLink
                          style={{ width: "100%" }}
                          className={BasicLineTab === "3" ? "active" : ""}
                          onClick={() => setLineTab("3")}
                        >
                          <i className="fa fa-map-marker"></i>
                          {trans("Leads Map")}
                        </NavLink>
                      </NavItem>
                    )}
                  </Nav>
                  <TabContent activeTab={BasicLineTab}>
                    <TabPane className="fade show" tabId="1">
                      <ListForms />
                    </TabPane>
                    <TabPane className="fade show" tabId="2">
                      <FormsListing />
                    </TabPane>
                    <TabPane className="fade show" tabId="3">
                      <LeadsMap />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default translate(LeadManagement);
